<template>
  <div class="calculator">
    <div class="calculator__form mb-5">
      <span class="mr-2">What is</span>
      <label for="percentage" class="mr-2">
        <input type="text" v-model="percentage" name="percentage" class="input" id="percentage" />
      </label>
      <span class="mr-2">% of</span>
      <label for="number" class="mr-2">
        <input type="text" v-model="number" name="number" class="input" />
      </label>
      <span>?</span>
    </div>

    <button class="button is-dark is-medium has-text-weight-bold"
            title="Calculate percentage"
            :disabled="percentage === null || number === null"
            @click="calculate">
      Calculate
    </button>

    <div class="calculator__answer">
      <p id="answer">{{ answer }}</p>
    </div>
    <button class="button is-dark is-small is-outlined"
            title="Clear"
            v-show="answer !== null"
            @click="clear">
      Clear
    </button>
  </div>
</template>

<script>
import fitty from 'fitty';

export default {
  name: 'PercentageCalculator',
  data() {
    return {
      percentage: null,
      number: null,
      answer: null,
    };
  },
  methods: {
    calculate() {
      this.answer = Number(this.number) * (Number(this.percentage) / 100);
    },
    clear() {
      this.percentage = null;
      this.number = null;
      this.answer = null;

      this.focusOnPercentage();
    },
    focusOnPercentage() {
      setTimeout(() => {
        document.getElementById('percentage').focus();
      }, 100);
    },
  },
  mounted() {
    this.focusOnPercentage();

    document.querySelectorAll('.input').forEach((el) => {
      el.addEventListener('keypress', (e) => {
        if (e.which === 13) {
          e.preventDefault();
          this.calculate();
        }
      });
    });

    fitty('#answer', {
      maxSize: 120,
    });
  },
};
</script>

<style lang="scss" scoped>
.calculator__form {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.25rem;

  input {
    max-width: 7rem;
    font-weight: bold;
  }
}

.calculator__answer {
  font-weight: bold;

  p {
    font-size: 7rem;
  }
}
</style>
