<template>
  <div class="container">
    <div class="hero mb-6 has-text-centered">
      <h1 class="title has-text-weight-bold is-1">Calculate Percentage</h1>

      <p class="subtitle">
        <a href="/">calculatepercentage.net</a> is a simple online tool to calculate percentages.
      </p>

      <CalculatorComponent/>
    </div>

    <InstructionsComponent/>
  </div>
</template>

<script>
// @ is an alias to /src
import CalculatorComponent from '@/components/CalculatorComponent.vue';
import InstructionsComponent from '@/components/InstructionsComponent.vue';

export default {
  name: 'HomeView',
  components: {
    CalculatorComponent,
    InstructionsComponent,
  },
};
</script>
