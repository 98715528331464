<!-- eslint-disable -->
<template>
  <hr/>

  <div class="has-text-centered mb-5">
    <h2 class="is-3 title">Calculating percentages</h2>
  </div>

  <div class="content mb-6">
    <p>Calculating percentages can be a useful skill to have in many different situations. Whether you're trying to figure out the sale price of an item, calculating your grades, or figuring out how much of a tip to leave at a restaurant, knowing how to calculate percentages can come in handy.</p>
    <p>So, how do you calculate a percentage? It's actually quite simple! Here's the basic process:</p>
    <ol>
      <li>
          <p>Identify the whole, or the total amount that you are finding the percentage of. This is often referred to as the base.</p>
      </li>
      <li>
          <p>Identify the part, or the value that you want to find the percentage of.</p>
      </li>
      <li>
          <p>Divide the part by the whole, and then multiply the result by 100 to express the answer as a percentage.</p>
      </li>
    </ol>
    <p>For example, let's say you want to find out what percentage of 50 is 10. The whole is 50, and the part is 10. To calculate the percentage, you would divide the part by the whole: 10 / 50 = 0.2. Then, you would multiply the result by 100 to express the answer as a percentage: 0.2 * 100 = 20%.</p>
    <p>So, in this example, 10 is 20% of 50.</p>
    <p>Here is the general formula for calculating a percentage:</p>
    <p>percentage = (part / whole) * 100</p>
    <p>You can also use this formula to calculate the whole if you know the percentage and the part, or to calculate the part if you know the percentage and the whole. Just rearrange the formula to solve for the unknown value. For example:</p>
    <p>whole = part / (percentage / 100) part = (percentage / 100) * whole</p>
    <p>Now that you know how to calculate a percentage, you can use this skill in a variety of situations. Here are just a few examples:</p>
    <ul>
      <li>
          <p>Calculating sale prices: If you see an item that is on sale for 20% off, you can use the percentage formula to figure out how much the item will cost after the discount. Just subtract the discount from the original price to find the sale price.</p>
      </li>
      <li>
          <p>Calculating grades: If you have a test with 100 points possible, and you score 80 points, you can use the percentage formula to figure out what percentage of the points you earned. In this case, your grade would be 80%.</p>
      </li>
      <li>
          <p>Calculating tips: If you want to leave a 20% tip at a restaurant, you can use the percentage formula to figure out how much money to leave. Just multiply the total bill by 0.2 (or 20/100) to find the amount of the tip.</p>
      </li>
    </ul>
    <p>I hope this tool has helped you understand how to calculate percentages. With a little practice, you'll be able to use this skill in a variety of situations. Good luck!</p>
  </div>

  <div class="has-text-centered mb-5">
    <h2 class="is-3 title">How to use this tool?</h2>
  </div>

  <div class="content mb-5">
    <p>Using a calculatepercentage.net is easy and can save you time when performing calculations involving percentages. Here are the steps to follow:</p>
    <ol>
        <li>
            <p>Enter the numbers you want to use in the calculator. For example, if you want to find 20% of 50, you would enter "50" and "20" in the appropriate fields.</p>
        </li>
        <li>
            <p>Click the "Calculate" or "Submit" button to see the result. The calculator will display the result of the calculation, which in this case would be 10.</p>
        </li>
        <li>
            <p>You can use the calculator to perform other calculations by simply entering different numbers and clicking the "Calculate" button again.</p>
        </li>
        <li>
            <p>If you want to clear the calculator and start a new calculation, click the "Clear" button.</p>
        </li>
    </ol>
    <p>That's all there is to it! Using a calculatepercentage.net online is a quick and easy way to perform calculations involving percentages.</p>
  </div>
</template>

<script>
export default {
  name: 'InstructionsComponent',
};
</script>

<style scoped>

</style>
<!-- eslint-enable -->
