<template>
  <section class="section">
    <div class="columns">
      <div class="column is-offset-3 is-6">
        <div class="has-text-centered">
          <img src="./assets/logo.svg" alt="Logo" id="logo" />

          <nav class="mb-6">
            <router-link class="has-text-weight-bold" to="/">Home</router-link> |
            <router-link class="has-text-weight-bold" to="/about">About</router-link>
          </nav>
        </div>

        <router-view/>
        <FooterComponent/>
      </div>
    </div>
  </section>
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  name: 'App',
  components: {
    FooterComponent,
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#logo {
  width: 5rem;
  margin-bottom: 2rem;
}
</style>
